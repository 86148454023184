
body {
  font-family: 'Museo Sans', Arial sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 160px;
  /* background-color: white; */
}

a.btn {
  text-decoration: none;
}
.btn {
  border-radius: 30px;
  box-shadow: none;
  font-weight: 700;
}
.btn-outline, .btn-outline:focus {
  border-radius: 0px;
  color: #9A9A9A;
  background-color: transparent;
  border: 1px dashed;
}
.btn-close, .btn-close:focus {
  height: 40px;
  background-color: #fff;
  border: 1px solid lightgray;
}

.navbar-row {
  line-height: 60px;
}
.navbar-row img {
  max-width: 100%;
}


hr {
  border-color: white;
}

.panel-body hr {
  border-color: lightgray;
}

.panel-success > .panel-heading {
    color: white;
}

.panel-title {
  font-size: 20px;
}
.panel-body {
  padding: 30px 15px;
}
.panel {
  border: none;
}
/* .btn-info { */
/*   background-color: #5F84DA; */
/* } */

/* .btn-default { */
/*   background-color: transparent; */
/*   border: 1px solid #A1A1A1; */
/* } */

/* :end: */

html {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Museo Sans', arial sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #666;
}

.list-group-item-heading {
  font-weight: 500;
}

a:hover,
a:active {
  color: #9AD14B;
}
.text-muted {
  color: #a1a1a1;
}
.btn:focus,
.btn:active,
.btn:active:focus {
  outline: none;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.avatar {
  font-size: 8em;
  background-color: #e7e7e7;
  color: #fff;
  margin: 20px auto;
  height: 1em;
  width: 1em;
  line-height: 1em;
  text-align: center;
  overflow: hidden;
}

.avatar-circle {
  border-radius: 50%;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}


/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 19px;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}

.marketing p + h4 {
  margin-top: 28px;
}

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
  .col-sm-border-right {
    border-right: 1px solid #EAEAE8;
  }
  .col-sm-text-center {
    text-align: center;
  }
}


/* @TODO move to kivra_theme for unified kivra-input style */
.form-control {
    height: 50px;
    font-size: 16px;
}

.input-group {
    display: block;
}

.form-group .input-group-addon-image {
    height: 58px;
}

.form-group .input-error {
    z-index: 100;
    font-size: 14px;
    position: relative;
    left: 0px;
    background-color: white;
    color: #EA552D;
    font-weight: normal;
    width: 100%;
    border-radius: 4px;
    padding: 0px;
}

.text-middle {
  vertical-align: middle !important;
}
.table {
  font-weight: 300;
}

.icon-badge {
  display: inline-block;
  padding: 0;
  width: 1.8em;
  height: 1.8em;
  line-height: 2.1em;
  border-radius: 50%;
}

.badge-lg {
  font-size: 22px;
}

.badge-muted {
  color: #a1a1a1;
  background-color: #eaeae8;
}

.img-tenant-icon {
  display: block;
  width: 100%;
  border-radius: 20%;
  background-color: #fff;
  border: 1px solid #eaeae8;
}

.list-group-item {
  padding: 15px;
  font-size: 16px;
}
.panel > .list-group-item:not(:last-child) {
  border-bottom: 1px solid #eaeae8;
}

.text-group {
  margin-bottom: 10px;
}

code {
  background-color: transparent;
  color: inherit;
  padding: 0;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-tight {
  line-height: 1em;
}

.text-normal {
  font-weight: 500;
}

.form-group-sm {
  margin-bottom: 10px;
}
.form-group-sm .control-label {
  font-size: 12px;
  margin-bottom: 0;
}
.form-group-sm .form-control-static {
  padding-left: 0;
}

.img-bankid-icon {
  width: 100%;
  max-width: 40px;
  height: auto;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .list-group-item:not(:first-child) {
    border-top: 1px solid #a1a1a1;
  }
  .img-bankid-icon {
    width: 12mm;
  }
}
